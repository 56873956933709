import React from "react";
import {toast} from "react-toastify";
import Form, {FormState} from "../common/form";
import deliveryNotePeriodService from "../../services/deliveryNotePeriodService";
import companyService from "../../services/companyService";
import confirm from "../../utils/confirm";
import {RouteComponentProps} from "react-router";
import SaveDeliveryNotePeriodDto from "../../models/saveDeliveryNotePeriodDto";
import CompanyDto from "../../models/companyDto";
import * as yup from "yup";
import * as ROUTE from "../../constants/route";
import setDocumentTitle from "../../utils/document";
import Required from "../common/required";
import DeliveryNotePeriodDto from "../../models/deliveryNotePeriodDto";
import {numberToString} from "../../utils/format";

interface State extends FormState<SaveDeliveryNotePeriodDto> {
  data: SaveDeliveryNotePeriodDto;
  companies: CompanyDto[];
  closed: boolean;
}

class DeliveryNotePeriodForm extends Form<
  SaveDeliveryNotePeriodDto,
  RouteComponentProps<{ id: string }>,
  State
> {
  state: State = {
    data: {
      year: "",
      month: "",
      price: "",
      companyId: "",
      percent: "",
      save: "",
    },
    errors: {},
    companies: [],
    closed: false,
  };
  schema = yup.object().shape<SaveDeliveryNotePeriodDto>({
    year: yup.string().required(),
    month: yup.string().required(),
    price: yup.string().required(),
    companyId: yup.string().required(),
    percent: yup.string().required(),
    save: yup.string().required(),
  });

  get title() {
    return this.id
      ? `${this.state.data.year} - ${this.state.data.month} időszak módosítása`
      : "Új időszak";
  }

  get id() {
    return this.props.match.params.id === "new"
      ? ""
      : this.props.match.params.id;
  }

  async componentDidMount() {
    await this.populateCompanies();
    if (this.id) await this.populateDeliveryNotePeriod();
  }

  async populateCompanies() {
    const {data: companies} = await companyService.getCompanies();
    this.setState({companies});
  }

  async populateDeliveryNotePeriod() {
    try {
      const {data} = await deliveryNotePeriodService.getDeliveryNotePeriod(
        this.id
      );
      const deliveryNotePeriod = this.mapToViewModel(data);
      this.setState({data: deliveryNotePeriod, closed: data.closed});
    } catch (ex) {
      this.handleError(ex);
    }
  }

  mapToViewModel(
    deliveryNotePeriod: DeliveryNotePeriodDto
  ): SaveDeliveryNotePeriodDto {
    return {
      year: numberToString(deliveryNotePeriod.year),
      month: numberToString(deliveryNotePeriod.month),
      price: numberToString(deliveryNotePeriod.price),
      companyId: numberToString(deliveryNotePeriod.companyId),
      percent: numberToString(deliveryNotePeriod.percent),
      save: numberToString(deliveryNotePeriod.save),
    };
  }

  doSubmit = async () => {
    try {
      await (this.id
        ? deliveryNotePeriodService.modifyDeliveryNotePeriod(
          this.id,
          this.state.data
        )
        : deliveryNotePeriodService.addDeliveryNotePeriod(this.state.data));
      toast.success("Időszak sikeresen mentve.");
      this.props.history.push(ROUTE.DELIVERY_NOTE_PERIODS);
    } catch (ex) {
      this.handleError(ex);
    }
  };

  handleRemove = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    confirm("Biztos törölni szeretné az időszakot?", async () => {
      try {
        await deliveryNotePeriodService.removeDeliveryNotePeriod(this.id);
        toast.success("Időszak sikeresen törölve.");
        this.props.history.replace(ROUTE.DELIVERY_NOTE_PERIODS);
      } catch (ex) {
        this.handleError(ex);
      }
    });
  };

  handleClose = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    confirm("Biztos le szeretné zárni az időszakot?", async () => {
      try {
        await deliveryNotePeriodService.closeDeliveryNotePeriod(this.id);
        toast.success("Időszak sikeresen lezárva.");
        this.props.history.replace(ROUTE.DELIVERY_NOTE_PERIODS);
      } catch (ex) {
        this.handleError(ex);
      }
    });
  };

  handleOpen = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    confirm("Biztos fel szertné oldani az időszakot?", async () => {
      try {
        await deliveryNotePeriodService.openDeliveryNotePeriod(this.id);
        toast.success("Időszak sikeresen feloldva.");
        this.props.history.replace(ROUTE.DELIVERY_NOTE_PERIODS);
      } catch (ex) {
        this.handleError(ex);
      }
    });
  };

  render() {
    setDocumentTitle(this.title);

    return (
      <div>
        <h1>{this.title}</h1>
        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-sm-6">
              {this.renderSelect<{ id: number; name: string }>(
                "year",
                <Required label="Év"/>,
                [
                  {id: 2018, name: "2018"},
                  {id: 2019, name: "2019"},
                  {id: 2020, name: "2020"},
                  {id: 2021, name: "2021"},
                  {id: 2022, name: "2022"},
                  {id: 2023, name: "2023"},
                  {id: 2024, name: "2024"},
                  {id: 2025, name: "2025"},
                ],
                (o) => o.name
              )}
            </div>
            <div className="col-sm-6">
              {this.renderSelect<{ id: number; name: string }>(
                "month",
                <Required label="Hónap"/>,
                [
                  {id: 1, name: "Január"},
                  {id: 2, name: "Február"},
                  {id: 3, name: "Március"},
                  {id: 4, name: "Április"},
                  {id: 5, name: "Május"},
                  {id: 6, name: "Június"},
                  {id: 7, name: "Július"},
                  {id: 8, name: "Augusztus"},
                  {id: 9, name: "Szeptember"},
                  {id: 10, name: "Október"},
                  {id: 11, name: "November"},
                  {id: 12, name: "December"},
                ],
                (o) => o.name
              )}
            </div>
          </div>
          {this.renderInput(
            "price",
            <Required label="NAV üzemanyag ár"/>,
            "number"
          )}
          {this.renderSelect(
            "companyId",
            <Required label="Cég"/>,
            this.state.companies,
            "nameOfTaxPlayer"
          )}
          {this.renderInput(
            "percent",
            <Required label="Megtakarítás max %"/>,
            "number"
          )}
          {this.renderInput(
            "save",
            <Required label="Megtakarítás max összeg"/>,
            "number"
          )}
          {!this.state.closed && this.renderButton("Mentés")}
          {this.id && !this.state.closed && (
            <button onClick={this.handleRemove} className="btn btn-danger ml-2">
              Törlés
            </button>
          )}
          {this.id && !this.state.closed && (
            <button onClick={this.handleClose} className="btn btn-warning ml-2">
              Lezárás
            </button>
          )}
          {this.id && this.state.closed && (
            <button onClick={this.handleOpen} className="btn btn-warning">
              Feloldás
            </button>
          )}
        </form>
      </div>
    );
  }
}

export default DeliveryNotePeriodForm;
